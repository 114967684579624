/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useSquadManagerService } from "../../context/useSquadManagerService";
import { createSearchParams, useSearchParams } from "react-router-dom";
import { ReactComponent as BackToLeaguesIcon } from "../../assets/icons/LeaguesIcons/backToLeaguesBtn.svg";
import { ReactComponent as BackArrows } from "../../assets/icons/LeaguesIcons/backBtnArrows.svg";
import { ReactComponent as NextArrows } from "../../assets/icons/LeaguesIcons/nextBtnArrows.svg";
import { notifyError, notifySucces } from "../../helper/Generic/Notifications";
import Player from "../../components/squadManagerComponents/MatchSetup/Player";
import FutureMatchHeader from "../../components/squadManagerComponents/MatchSetup/FutureMatchHeader";
import FinishedMatchHeader from "../../components/squadManagerComponents/MatchSetup/FinishedMatchHeader";
import PlayerFinishedMatch from "../../components/squadManagerComponents/MatchSetup/PlayerFinishedMatch";
import FinishedMatchHeader2 from "../../components/squadManagerComponents/MatchSetup/FinishedMatchHeader2";
import MatchElement from "../../components/squadManagerComponents/MatchSetup/MatchElement";
import PlayerFinishedMatch2 from "../../components/squadManagerComponents/MatchSetup/PlayerFinishedMatch2";
import { useNavigate } from "react-router-dom";
import { mapTeamDetails } from "../../helper/Analyst/SquadManager/mapTeamDetails";
import NotePopupPanel from "../../components/notes/NotePopupPanel";
import SquadFixtureNotePopupPanel from "../../components/notes/SquadFixtureNotePopupPanel";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import cloneDeep from "lodash.clonedeep";
import { format_time_to_timezone } from "../../helper/Generic/dateTimeFormat";
import { useSelector } from "react-redux";
import { selectUser } from "../../Redux/user/user";
import moment from "moment";
import { useMatchPreviewService } from "../../context/useMatchPreviewService";
import { calculateStartingChanceFromPlayers } from "../../helper/lineup/LineupHelper";
import { ReactComponent as EditPencilIcon } from "../../assets/icons/LeaguesIcons/editPencilIcon.svg";
import { FullScreenSpinner } from "../../components/genericComponents/FullScreenSpinner";
import EditPlayersView from "../../components/squadManagerComponents/EditPlayersView";
import FilterFixturesMenu from "../../components/squadManagerComponents/Menu/FilterFixturesMenu";
import TransferDataModal from "../../components/squadManagerComponents/TransferDataModal";
import { useLeaguesDataService } from "../../context/useLeaguesDataService";
import CupsForTeamMenu from "../../components/squadManagerComponents/Menu/CupsForTeamMenu";
import LeaguesForTeamMenu from "../../components/squadManagerComponents/Menu/LeaguesForTeamMenu";
import GroupPlayersModal from "../../components/squadManagerComponents/GroupPlayersModal";
import { ReactComponent as TransferIcon } from "../../assets/icons/LeaguesIcons/transferDataButton.svg";
import { ReactComponent as ResetIcon } from "../../assets/icons/LeaguesIcons/reset-icon.svg";
import ActionWithConfirmation from "../../components/ActionWithConfirmation";
import PmCompleteHeaderEdit from "../../components/squadManagerComponents/MatchSetup/PmCompleteHeaderEdit";
import PlayerPmCompleteEdit from "../../components/squadManagerComponents/MatchSetup/PlayerPmCompleteEdit";
import PlayerMetricsSeasonCard from "../../components/squadManagerComponents/PlayerMetricsSeasonCard";
import { usePlayerStatsService } from "../../context/usePlayerStatsService";
import { Popover } from "@mui/material";
import { Menu, useContextMenu } from "react-contexify";
import "react-contexify/dist/ReactContexify.css";
import { ReactComponent as GoToStatsIcon } from "../../assets/icons/LeaguesIcons/GoToTeamStatsIconNoBorder.svg";
import { error } from "jquery";
import { mergeSquadDetailsStats } from "../../helper/Analyst/SquadManager/MergeSquadDetails";

const SquadManager = () => {
  const {
    get_squads_for_team,
    get_squads_stats_team,
    save_new_squad_for_team,
    submit_new_squad_for_team,
    update_selection,
    get_player_stats,
    edit_selections,
    get_squad_available_for_data_transfer,
    transfer_squad_and_game_data,
    reset_squad,
  } = useSquadManagerService();

  const { get_cups_for_teams, get_leagues_for_teams } = useLeaguesDataService();

  const { getPlayerStatsForSeason, getPlayerStatsForGame } =
    usePlayerStatsService();

  const { set_confirmed_attendance } = useMatchPreviewService();
  const [formation, setFormation] = useState(null);
  const [readyToSave, setReadyToSave] = useState(false);
  const [readyToSubmit, setReadyToSubmit] = useState(false);
  const [allPlayersConfirmed, setAllPlayersConfirmed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fixtureList, setFixtureList] = useState([]);
  const [selectedFixture, setSelectedFixture] = useState(null);
  const [squadData, setSquadData] = useState([]);
  const squadStatsRef = useRef();
  const fixtureListRef = useRef();
  const [orderList, setOrderList] = useState([]);

  const [playerAverageStats, setPlayerAverageStats] = useState({
    average_player_stats: null,
    league: null,
    team: null,
    fixture: null,
    seasonAverage: true,
  });

  const [showPlayerNotes, setShowPlayerNotes] = useState(false);
  const [showFixtureNotes, setShowFixtureNotes] = useState(false);
  const [playerStatsPopupLoading, setPlayerStatsPopupLoading] = useState(false);
  const [playerForConversation, setPlayerForConversation] = useState(null);
  const [fixtureForConversation, setFixtureForConversation] = useState(null);
  const [squadDataUnsavedChanges, setSquadDataUnsavedChanges] = useState();

  const [homeTeam, setHomeTeam] = useState(null);
  const [awayTeam, setAwayTeam] = useState(null);
  const [thisTeam, setThisteam] = useState();

  const [editBeforeGameStart, setEditBeforeGameStart] = useState(false);
  const [editInPhase3, setEditInPhase3] = useState(false);
  const [numberOfSelectedXI, setNumberOfSelectedXI] = useState(0);
  const [startingChange, setStartingChance] = useState(null);

  const [lineupSubmitted, setLineupSubmitted] = useState();
  const [lineupConfirmed, setLineupConfirmed] = useState();
  const [pmCompleted, setPmCompleted] = useState();

  const [savedLineup, setSavedLineup] = useState();
  const [highlightedElement, setHiglightedElement] = useState([]);
  const [showEditPlayersModal, setShowEditPlayersModal] = useState(false);
  const [showTransferDataModal, setShowTransferDataModal] = useState(false);

  const [matchTransferOptionsList, setMatchTransferOptionsList] = useState([]);
  const [transferDataToThisMatch, setTransferDataToThisMatch] = useState(null);

  const [scrollPosition, setScrollPosition] = useState({
    left: false,
    right: true,
  });

  const listRef = useRef(null);
  const refreshOnClose = useRef(null);

  const user = useSelector(selectUser);

  const [isConfAttendanceOpen, setIsConfAttendanceOpen] = useState(false);
  const [disalbedForAction, setDisalbedForAction] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [fixtureFilter, setFixtureFilter] = useState({
    side: "All",
    filter: "All",
  });
  const [cupListForTeam, setCupListForTeam] = useState([]);
  const [leagueListForTeam, setLeagueListForTeam] = useState();

  const [selectedCupsForTeam, setSelectedCupsForTeam] = useState(
    searchParams.get("selectedCupId")
      ? [parseInt(searchParams.get("selectedCupId"))]
      : []
  );
  const [selectedLeagueForTeam, setSelectedLeagueForTeam] = useState(
    searchParams.get("selectedLeagueForTeam")
      ? parseInt(searchParams.get("selectedLeagueForTeam"))
      : null
  );

  const [groupPlayersOpen, setGroupPlayersOpen] = useState(false);
  const [openActionWithConfirmation, setOpenActionWithConfirmation] =
    useState(false);

  const MENU_ID = "menu-id";

  const { show } = useContextMenu({
    id: MENU_ID,
  });

  const handleShowPlayerNotes = () => {
    setShowPlayerNotes(!showPlayerNotes);
  };

  const handleShowTeamNotes = () => {
    setShowFixtureNotes(!showFixtureNotes);
  };

  const displayPlayerNotes = (fixture, player) => {
    setFixtureForConversation(fixture);
    setPlayerForConversation(player);
    setShowPlayerNotes(true);
  };

  const displayTeamNotes = (fixture) => {
    setFixtureForConversation(fixture);
    setShowFixtureNotes(true);
  };

  //initialize player position with position_1
  const initializeSquad = (squad) => {
    if (!squad) {
      return;
    }
    const newSquad = cloneDeep(squad);
    newSquad.map((item) => {
      if (item.position) {
        item.position = item.position;
      } else {
        item.position = item.player?.position_1;
      }
    });
    return newSquad;
  };

  const handleSelectFixture = (item) => {
    setSelectedFixture(item);
    setEditBeforeGameStart(false);
    setEditInPhase3(false);
    setSearchParams((params) => {
      params.set("fixtureId", item?.fixture.fixture_id);
      return params;
    });

    if (item) {
      if (item.squad.fixture_id != null) {
        let initializedSquad = initializeSquad(item.squad.selections);
        setSquadData(initializedSquad);
        setFormation(item.squad.formation);
      } else {
        let initializedSquad = initializeSquad(item.squad);
        setSquadData(initializedSquad);
      }
    } else {
      setFormation(null);
      setSquadData([]);
    }
  };

  const displayPlayerAverageStats = (event, season_id, team_id, player_id) => {
    setPlayerStatsPopupLoading(true);

    getPlayerStatsForSeason(
      season_id,
      team_id,
      player_id,
      function (response) {
        const resp = response.data;

        if (resp.average_player_stats.length > 0) {
          setPlayerAverageStats({
            average_player_stats: resp.average_player_stats[0],
            team: resp.team,
            fixture: null,
            seasonAverage: true,
          });
        } else {
          setPlayerAverageStats({
            average_player_stats: null,
            team: null,
            fixture: null,
            seasonAverage: true,
          });
        }
        setPlayerStatsPopupLoading(false);
      },
      function (error) {
        console.log(error);
      }
    );
    show({
      event: event,
    });
  };

  const displayPlayerGameStats = (event, fixture_id, team, player, fixture) => {
    setPlayerStatsPopupLoading(true);
    getPlayerStatsForGame(
      fixture_id,
      team.id,
      player.player_id,
      function (response) {
        const resp = response.data;

        if (resp.player_stats) {
          setPlayerAverageStats({
            average_player_stats: resp.player_stats,
            team: resp.team,
            fixture: fixture,
            seasonAverage: false,
          });
        } else {
          setPlayerAverageStats({
            average_player_stats: {
              player_data: player.player,
            },
            team: team,
            fixture: fixture,
            seasonAverage: false,
          });
        }

        setPlayerStatsPopupLoading(false);
      },
      function (error) {
        console.log(error);
      }
    );

    show({
      event: event,
    });
  };

  const verifySaveStep1 = () => {
    let xi = 0;
    squadData?.forEach((element) => {
      if (element.xi) {
        xi = xi + 1;
      }
      setNumberOfSelectedXI(xi);
    });

    if (squadData && selectedFixture?.squad?.selections) {
      let squadDataValidItems = squadData.filter((item) => item.id);
      let selectedFixtureValidItems = selectedFixture.squad.selections.filter(
        (item) => item.id
      );

      if (_.isEqual(squadDataValidItems, selectedFixtureValidItems)) {
        setSquadDataUnsavedChanges(false);
      } else {
        setSquadDataUnsavedChanges(true);
      }
    }

    if (formation) {
      setReadyToSave(true);
    } else {
      setReadyToSave(false);
    }

    if (numberOfSelectedXI == 11 && formation) {
      setReadyToSubmit(true);
    } else {
      setReadyToSubmit(false);
    }
  };

  const verifySaveStep2 = () => {
    let validsquad = squadData?.filter((item) => item.id);
    let confirmed = squadData?.filter(
      (item) => item.id && item.confirmed === true
    );

    if (validsquad?.length == confirmed?.length) {
      setAllPlayersConfirmed(true);
    } else {
      setAllPlayersConfirmed(false);
    }
  };

  const handleRecentPlayers = (fixturesList) => {
    //verify if newer fixtures have new players and add empty elements (containing player_id)  to older fixtures
    //this make draggableObject to work properly

    if (fixturesList.length < 1) {
      return [];
    }

    let copy = cloneDeep(fixturesList);

    var allPlayersIdsSet = new Set();
    var transferredPlayersSet = new Set();

    copy.forEach((element) => {
      element.squad.selections?.forEach((element) => {
        allPlayersIdsSet.add(element.player_id);

        if (element.player.transferred) {
          transferredPlayersSet.add(element.player_id);
        }
      });
    });

    copy.forEach((fixture) => {
      // Find all the players from the current selection
      var currentPlayersSet = new Set();
      fixture.squad.selections?.forEach((element) => {
        currentPlayersSet.add(element.player_id);
      });

      const missingPlayers = new Set(
        [...allPlayersIdsSet].filter((x) => !currentPlayersSet.has(x))
      );

      missingPlayers.forEach((playerID) => {
        const isTransfered = transferredPlayersSet.has(playerID);
        let alreadyInTeam = fixture.squad.selections.find(
          (i) => i.player_id === playerID
        );
        if (alreadyInTeam) {
          return;
        }

        fixture.squad.selections.push({
          id: null,
          player_id: playerID,
          player: {
            transferred: isTransfered,
          },
          position: undefined,
        });
      });
    });

    return copy;
  };

  const handleFixtureList = (resp) => {
    let mappedTeamDetails = mapTeamDetails(resp);

    let displayFixtures4DaysInAdvance =
      display4DaysInAdvance(mappedTeamDetails);
    let fixturesWithRecentPlayers = handleRecentPlayers(
      displayFixtures4DaysInAdvance
    );

    setFixtureList(fixturesWithRecentPlayers);

    return fixturesWithRecentPlayers;
  };

  const getTeamData = (onSuccess) => {
    setIsLoading(true);
    setDisalbedForAction(true);

    let dataObj = {};

    let teamId = searchParams.getAll("teamId");

    if (teamId) {
      dataObj.team_id = parseInt(teamId);
    }
    if (selectedLeagueForTeam) {
      dataObj.league_ids = [selectedLeagueForTeam];
    }

    if (selectedCupsForTeam.length > 0) {
      dataObj.cup_ids = selectedCupsForTeam;
    }


    get_squads_for_team(
      dataObj,
      function (response) {
        let resp = response.squad_details;
        fixtureListRef.current = resp;
        if (squadStatsRef.current) {
          resp = mergeSquadDetailsStats(resp, squadStatsRef.current);
        }

        let fixturesWithRecentPlayers = handleFixtureList(resp);
        onSuccess(fixturesWithRecentPlayers);
        setIsLoading(false);
        setDisalbedForAction(false);
      },
      function (error) {
        notifyError("Something wrong");
        setDisalbedForAction(false);
        setIsLoading(false);
      }
    );

    get_squads_stats_team(
      dataObj,
      (response) => {
        let resp = response.squad_stats;
        squadStatsRef.current = response.squad_stats;

        if (fixtureListRef.current?.length > 1) {
          resp = mergeSquadDetailsStats(fixtureListRef.current, resp);
          let fixturesWithRecentPlayers = handleFixtureList(resp);
          onSuccess(fixturesWithRecentPlayers);
        }
      },
      (error) => {
        notifyError("Something wrong");
      }
    );
  };

  const loadInitialData = () => {
    getTeamData((response) => {
      let last = response.length;
      //if is selected fixture then reset it
      if (selectedFixture) {
        let found = response.find(
          (item) =>
            item.fixture.fixture_id == selectedFixture.fixture.fixture_id
        );
        if (found) {
          handleSelectFixture(found);
        } else {
          handleSelectFixture(null);
        }
      }
      //if is no fixture then set last fixture from list
      else if (response.length > 0) {
        if (searchParams.get("fixtureId")) {
          let idF = searchParams.get("fixtureId");
          let found = response.find((item) => item.fixture.fixture_id == idF);
          if (found) {
            handleSelectFixture(found);
          } else {
            handleSelectFixture(response[last - 1]);
          }
        } else {
          handleSelectFixture(response[last - 1]);
        }
      }
    });
    setEditBeforeGameStart(false);
    setEditInPhase3(false);
    refreshOnClose.current = false;
  };

  const createOrderList = () => {
    let list = [];
    squadData?.forEach((item) => {
      list.push(item.player_id);
    });
    setOrderList(list);
  };

  const saveNewSquad = () => {
    setIsLoading(true)
    setDisalbedForAction(true);
    let selections = [];
    let data;

    squadData.forEach((item) => {
      if (item.player.wy_id) {
        data = {
          id: item.id,
          player_id: item.player_id,
          position: item.position,
          fatigue: item.fatigue,
          squad_number: item.squad_number,
          status: item.status,
          importance: item.importance,
          attack_rating: item.attack_rating,
          defence_rating: item.defence_rating,
          long_term_formation: item.long_term_formation,
          starting_chance: item.starting_chance,
          xi: item.xi,
        };
        selections.push(data);
      }
    });

    var dataObject = {
      team_id: searchParams.get("teamId"),
      fixture_id: selectedFixture.fixture.fixture_id,
      formation: formation,
      selections: selections,
    };

    if (selectedFixture.cup) {
      dataObject.cup_id = selectedFixture.cup.id;
    }

    save_new_squad_for_team(
      dataObject,
      function (response) {
        notifySucces("Squad saved");

        loadInitialData();
      },
      function (error) {
        notifyError("Something wrong");
      }
    );
  };

  const submitNewSquad = () => {
    let selections = [];
    let data;
    setIsLoading(true);
    setDisalbedForAction(true);
    squadData.forEach((item) => {
      if (item.player.wy_id) {
        data = {
          id: item.id,
          player_id: item.player_id,
          position: item.position,
          fatigue: item.fatigue,
          squad_number: item.squad_number,
          status: item.status,
          importance: item.importance,
          attack_rating: item.attack_rating,
          defence_rating: item.defence_rating,
          long_term_formation: item.long_term_formation,
          starting_chance: item.starting_chance,
          xi: item.xi,
        };
        selections.push(data);
      }
    });

    var dataObject = {
      team_id: searchParams.get("teamId"),
      fixture_id: selectedFixture.fixture.fixture_id,
      formation: formation,
      selections: selections,
    };

    if (selectedFixture.cup) {
      dataObject.cup_id = selectedFixture.cup.id;
    }

    submit_new_squad_for_team(
      dataObject,
      function (response) {
        notifySucces("Squad submitted");
        loadInitialData();
      },
      function (error) {
        notifyError("Something wrong");
      }
    );
  };

  const updateSquad = () => {
    setIsLoading(true);
    setDisalbedForAction(true);

    let selections = [];
    squadData.forEach((item) => {
      if (item.player.wy_id) {
        selections.push({
          id: item.id,
          injured: item.injured,
          match_rating: item.match_rating,
          confirmed: item.confirmed,
          importance: item.importance,
          attack_rating: item.attack_rating,
          defence_rating: item.defence_rating,
          long_term_formation: item.long_term_formation,
        });
      }
    });

    const dataObject = {
      fixture_id: selectedFixture.fixture.fixture_id,
      league_id: selectedFixture.league.id,
      squad_id: selectedFixture.squad.id,
      selections: selections,
    };
    if (selectedFixture.cup) {
      dataObject.cup_id = selectedFixture.cup.id;
    }

    update_selection(
      dataObject,
      // selectedFixture.fixture.fixture_id,
      // selectedFixture.league.id,
      // selectedFixture.squad.id,
      // selections,
      function (response) {
        notifySucces("Confirmed");
        loadInitialData();
      },
      function (error) {
        notifyError("Something wrong...");
        setIsLoading(false);
        setDisalbedForAction(false);
      }
    );
  };

  const editSquad = () => {
    setDisalbedForAction(true);
    let selections = [];
    setIsLoading(true);

    squadData.forEach((item) => {
      if (item.id == null) {
        return;
      }

      selections.push({
        id: item.id,
        player_id: item.player_id,
        position: item.position,
        fatigue: item.fatigue,
        squad_number: item.squad_number,
        status: item.status,
        importance: item.importance,
        attack_rating: item.attack_rating,
        defence_rating: item.defence_rating,
        long_term_formation: item.long_term_formation,
        starting_chance: item.starting_chance,
        xi: item.xi,
      });
    });

    edit_selections(
      selectedFixture.fixture.fixture_id,
      selectedFixture.league.id,
      selectedFixture.squad.id,
      formation,
      selections,
      function (response) {
        notifySucces("Confirmed");
        loadInitialData();
      },
      function (error) {
        notifyError("Something went wrong...");
        setIsLoading(false);
        setDisalbedForAction(false);
      }
    );
  };

  const handleFieldChange = (id, field, value) => {
    setSquadData((prevData) => {
      const newData = prevData?.map((item) => {
        if (item.player_id === id) {
          return { ...item, [field]: value };
        }
        return item;
      });
      return newData;
    });
  };

  const increment = () => {
    document.getElementById("matchList").scrollLeft += 168;
  };
  const decrement = () => {
    document.getElementById("matchList").scrollLeft += -168;
  };

  const onScroll = () => {
    if (listRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = listRef.current;

      setScrollPosition({
        right: listRef.current.scrollLeft == 0,
        left: clientWidth - scrollWidth == scrollLeft,
      });
    }
  };

  const reorderList = (result) => {
    // here
    const startIndex = result.source.index;
    const endIndex = result.destination.index;

    setSquadData((oldValue) => {
      const newValue = [...oldValue];
      const [removed] = newValue.splice(startIndex, 1);
      newValue.splice(endIndex, 0, removed);
      return newValue;
    });
  };

  const handleBackToLeagues = () => {
    let newParams = searchParams;

    newParams.delete("teamId");
    newParams.delete("selectedCupId");
    newParams.delete("selectedLeagueForTeam");
    newParams.delete("leagueId");

    navigate({
      pathname: "/leagues",
      search: createSearchParams(newParams).toString(),
    });
  };

  const handleCheckAll = (checked) => {
    let copy = cloneDeep(squadData);
    if (checked) {
      copy.map((item) => {
        if (item.player) {
          return (item.confirmed = true);
        }
      });
    }

    if (!checked) {
      copy.map((item) => {
        return (item.confirmed = false);
      });
    }
    setSquadData(copy);
  };

  const handleMatchPreviewButton = (fixture, projectedLineup = false) => {
    let paramsToSend;
    let paramsObject = searchParams;

    let fixtureStartTime = format_time_to_timezone(
      fixture.fixture.start_time,
      user.timezone
    );

    let start_date = fixtureStartTime.startOf("date").toISOString();
    let end_date = fixtureStartTime.endOf("date").toISOString();

    if (fixture.cup) {
      paramsObject.set("cupIds", fixture.cup.id);
      paramsObject.delete("seasonIds");
    } else {
      paramsObject.delete("cupIds");
      paramsObject.set("seasonIds", fixture.league.season_id);
      paramsObject.delete("selectedCupId");
    }
    paramsObject.delete("selectedLeagueForTeam");
    paramsObject.delete("leagueIds");
    paramsObject.delete("countryId");
    paramsObject.set("start_date", start_date);
    paramsObject.set("end_date", end_date);

    if (projectedLineup == false) {
      paramsToSend = createSearchParams(paramsObject).toString();
    }

    if (projectedLineup == true) {
      paramsObject.set("projected_lineup", true);
      paramsToSend = createSearchParams(paramsObject).toString();
    }

    if (user.open_linnks_in_new_tabs) {
      let url = {
        pathname: "/leagues/match_preview",
        search: paramsToSend,
      };
      window.open(
        `${url.pathname}?${url.search}`,
        "_blank",
        "noopener,noreferrer"
      );
    } else {
      navigate({
        pathname: "/leagues/match_preview",
        search: paramsToSend,
      });
    }
  };

  //filter fixtures wich start before todayDate + 4days
  const display4DaysInAdvance = (fixtureList) => {
    if (!fixtureList) {
      return;
    }
    let startOfToday = moment().startOf("day").toISOString();
    let dateFourDaysLater = moment().add(4, "days").endOf("day").toISOString();

    //filter fixtures wich  "today < fixtures start< 4days later"
    var fixturesBetweenTodayAnd4DaysLater = fixtureList.filter((fixture) => {
      return (
        fixture.fixture.start_time > startOfToday &&
        fixture.fixture.start_time < dateFourDaysLater
      );
    });

    let firstFixtureAfter4Days = fixtureList.find((fixture) => {
      return fixture?.fixture?.start_time > dateFourDaysLater;
    });

    var fixturesToDisplay = fixtureList.filter((fixture) => {
      return fixture.fixture.start_time < dateFourDaysLater;
    });

    //if no fixtures between today and 4daysLater add first fixture if exist
    if (
      fixturesBetweenTodayAnd4DaysLater.length == 0 &&
      firstFixtureAfter4Days
    ) {
      fixturesToDisplay.push(firstFixtureAfter4Days);
    }

    // If no fixtures are present in the next 4 days, display the first one
    if (fixturesToDisplay.length == 0 && fixtureList.length > 0) {
      fixturesToDisplay = [fixtureList[0]];
    }

    fixturesToDisplay.sort((a, b) => a.fixture?.start_time > b.fixture?.start_time ? 1 : -1)

    return fixturesToDisplay;
  };

  const handleOpenConfAttendance = () => {
    setIsConfAttendanceOpen(!isConfAttendanceOpen);
  };

  const handleSetConfirmedAttendance = (
    fixture_id,
    league_id,
    confirmed_attendance,
    cup_id = null,
    onSuccess
  ) => {
    setIsLoading(true);

    let dataObj = {
      fixture_id: fixture_id,
      confirmed_attendance: confirmed_attendance,
      league_id: league_id,
    };

    if (cup_id) {
      dataObj.cup_id = cup_id;
    }


    set_confirmed_attendance(
      dataObj,
      function (response) {
        setIsLoading(false);
        notifySucces("Confirmed attendance");
        loadInitialData();
        onSuccess();
      },
      function () {
        setIsLoading(false);
        notifyError("Something wrong....");
      }
    );
  };

  const checkSubmittedLineup = () => {
    if (!selectedFixture) {
      return;
    }

    let homeTeam;
    let awayTeam;
    let thisTeam;

    if (selectedFixture?.fixture.participant1is_home) {
      homeTeam = selectedFixture?.fixture.team1;
      awayTeam = selectedFixture?.fixture.team2;
    } else {
      homeTeam = selectedFixture?.fixture.team2;
      awayTeam = selectedFixture?.fixture.team1;
    }

    if (searchParams.get("teamId") == selectedFixture?.fixture?.team1.id) {
      thisTeam = selectedFixture.fixture.team1;
    }
    if (searchParams.get("teamId") == selectedFixture?.fixture?.team2.id) {
      thisTeam = selectedFixture.fixture.team2;
    }

    if (thisTeam?.id == homeTeam?.id) {
      setLineupSubmitted(selectedFixture.game.home_lineup_submitted);
      setLineupConfirmed(selectedFixture.game.home_lineup_confirmed);
      setPmCompleted(selectedFixture.game.pm_home_completed);
      setSavedLineup(selectedFixture.game.home_lineup_saved);
    } else {
      setLineupSubmitted(selectedFixture.game.away_lineup_submitted);
      setLineupConfirmed(selectedFixture.game.away_lineup_confirmed);
      setPmCompleted(selectedFixture.game.pm_away_completed);
      setSavedLineup(selectedFixture.game.away_lineup_saved);
    }
  };

  const handleMessageicon = (fixture, player, setHaveMessage) => {
    let fixtureCLone = cloneDeep(fixture);
    let listClone = cloneDeep(fixtureList);

    fixtureCLone.squad.selections.map((item) => {
      if (item.player_id == player.player_id) {
        item.has_message = setHaveMessage;
      }
      return item;
    });

    let newfixtureList = listClone.map((item, index) => {
      if (item.fixture.fixture_id == fixtureCLone.fixture.fixture_id) {
        item = fixtureCLone;
      }
      return item;
    });

    setFixtureList(newfixtureList);
  };

  const focusOnOtherTeam = (team_id) => {
    let newSearchParams = searchParams;
    newSearchParams.set("teamId", team_id);
    setFixtureList([]);
    setSquadData([]);

    let paramsToSend = createSearchParams(newSearchParams).toString();

    let url = {
      pathname: "squad_manager",
      search: paramsToSend,
    };

    if (user.open_links_in_new_tabs) {
      window.open(`${url.pathname}?${url.search}`, "_blank");
    } else {
      navigate({
        pathname: "/leagues/squad_manager",
        search: paramsToSend,
      });
      // window.location.replace(`${url.pathname}?${url.search}`);
    }
  };

  const handleHightlight = (elementIndex) => {
    let newList = cloneDeep(highlightedElement);
    let found = newList.find((element) => element == elementIndex);
    if (found != undefined) {
      newList = newList.filter((element) => element != found);
    } else {
      newList.push(elementIndex);
    }

    setHiglightedElement(newList);
  };
  const unhighlightAll = () => {
    setHiglightedElement([]);
  };

  const checkHighlightedElement = (element) => {
    let found = highlightedElement.find((item) => item == element);
    if (found != undefined) {
      return true;
    } else {
      return false;
    }
  };
  const calculateStartingChances = () => {
    if (!squadData || squadData == []) {
      return;
    }
    const selectionScore = calculateStartingChanceFromPlayers(squadData);
    setStartingChance(selectionScore);
  };

  const populateTeams = () => {
    if (!selectedFixture) {
      return;
    }
    if (selectedFixture.fixture.participant1is_home) {
      setHomeTeam(selectedFixture.fixture.team1);
      setAwayTeam(selectedFixture.fixture.team2);
    } else {
      setHomeTeam(selectedFixture.fixture.team2);
      setAwayTeam(selectedFixture.fixture.team1);
    }
  };

  const checkThisTeam = () => {
    if (searchParams.get("teamId") == selectedFixture?.fixture?.team1.id) {
      setThisteam(selectedFixture.fixture.team1);
    }
    if (searchParams.get("teamId") == selectedFixture?.fixture?.team2.id) {
      setThisteam(selectedFixture.fixture.team2);
    }
  };

  const closeEditPlayersWindow = () => {
    if (refreshOnClose.current == true) {
      setShowEditPlayersModal(false);
      loadInitialData();
    }
    setShowEditPlayersModal(false);
  };

  const displayEditPlayersWindow = () => {
    setShowEditPlayersModal(!showEditPlayersModal);
  };
  const setWindowDimensions = () => {
    setWindowWidth(window.innerWidth);
  };

  const fixtureListFilter = (item) => {
    if (fixtureFilter.side == "All") {
      if (fixtureFilter.filter == "Submitted") {
        if (
          item.game.home_lineup_submitted ||
          item.game.away_lineup_submitted
        ) {
          return item;
        }
      }

      if (fixtureFilter.filter == "Completed") {
        if (item.game.pm_home_completed || item.game.pm_away_completed) {
          return item;
        }
      }

      if (fixtureFilter.filter == "All") {
        return item;
      }
      if (fixtureFilter.filter == "Hide Deferred") {
        if (!item.game.postponed && !item.game.cancelled) {
          return item;
        }
      }
    }

    if (fixtureFilter.side == "Home") {
      if (item.fixture.participant1is_home) {
        if (thisTeam.name == item.fixture.team1.name) {
          if (fixtureFilter.filter == "All") {
            return item;
          }

          if (fixtureFilter.filter == "Submitted") {
            if (item.game.home_lineup_submitted) {
              return item;
            }
          }

          if (fixtureFilter.filter == "Completed") {
            if (item.game.pm_home_completed) {
              return item;
            }
          }
          if (fixtureFilter.filter == "Hide Deferred") {
            if (!item.game.postponed && !item.game.cancelled) {
              return item;
            }
          }
        }
      } else if (item.fixture.participant1is_home == false) {
        if (thisTeam.name == item.fixture.team2.name) {
          if (fixtureFilter.filter == "All") {
            return item;
          }

          if (fixtureFilter.filter == "Submitted") {
            if (item.game.away_lineup_submitted) {
              return item;
            }
          }
          if (fixtureFilter.filter == "Completed") {
            if (item.game.pm_away_completed) {
              return item;
            }
          }
          if (fixtureFilter.filter == "Hide Deferred") {
            if (!item.game.postponed && !item.game.cancelled) {
              return item;
            }
          }
        }
      }
    }

    if (fixtureFilter.side == "Away") {
      if (item.fixture.participant1is_home) {
        if (thisTeam.name == item.fixture.team2.name) {
          if (fixtureFilter.filter == "All") {
            return item;
          }

          if (fixtureFilter.filter == "Submitted") {
            if (item.game.away_lineup_submitted) {
              return item;
            }
          }
          if (fixtureFilter.filter == "Completed") {
            if (item.game.pm_away_completed) {
              return item;
            }
          }
          if (fixtureFilter.filter == "Hide Deferred") {
            if (!item.game.postponed && !item.game.cancelled) {
              return item;
            }
          }
        }
      } else if (item.fixture.participant1is_home == false) {
        if (thisTeam.name == item.fixture.team1.name) {
          if (fixtureFilter.filter == "All") {
            return item;
          }

          if (fixtureFilter.filter == "Submitted") {
            if (item.game.home_lineup_submitted) {
              return item;
            }
          }

          if (fixtureFilter.filter == "Completed") {
            if (item.game.pm_home_completed) {
              return item;
            }
          }
          if (fixtureFilter.filter == "Hide Deferred") {
            if (!item.game.postponed && !item.game.cancelled) {
              return item;
            }
          }
        }
      }
    }
  };

  const handleShowTransferModal = () => {
    // document.body.style.overflow = "hidden"
    setShowTransferDataModal(true);
  };

  const getFixturesAvailableForDataTransfer = (fixtureId, date) => {
    setIsLoading(true);
    get_squad_available_for_data_transfer(
      fixtureId,
      date,
      function (response) {
        if (response.fixtures.length > 0) {
          setMatchTransferOptionsList(response.fixtures[0].fixtures);
        } else {
          setMatchTransferOptionsList([]);
        }

        setIsLoading(false);
      },
      function (error) {
        console.log(error);
        setIsLoading(false);
        notifyError("Something wrong...");
      }
    );
  };

  const handleSquadAndGameDataTransfer = (from_fixture_id, to_fixture_id) => {
    setIsLoading(true);

    transfer_squad_and_game_data(
      from_fixture_id,
      to_fixture_id,
      function (response) {
        setIsLoading(false);
        refreshOnClose.current = true;
        handleTransferMoldaClose();
      },
      function (error) {
        notifyError("Something wrong...");
        setIsLoading(false);
        console.log(error);
      }
    );
  };

  const handleTransferMoldaClose = () => {
    if (refreshOnClose.current == true) {
      setShowTransferDataModal(false);
      setMatchTransferOptionsList([]);
      setTransferDataToThisMatch(null);
      loadInitialData();
    }
    setShowTransferDataModal(false);
    setMatchTransferOptionsList([]);
    setTransferDataToThisMatch(null);
  };

  const handleTransferDataButton = () => {
    handleSquadAndGameDataTransfer(
      selectedFixture.fixture.fixture_id,
      transferDataToThisMatch.fixture_id
    );
  };

  const handleGetLeagueListForTeam = (teamId) => {
    get_leagues_for_teams(
      teamId,
      function (response) {
        setLeagueListForTeam(response.data.league);
      },
      function (error) {
        notifyError("Something wrong");
        console.log(error);
      }
    );
  };

  const handleGetCupListForTeam = (teamId) => {
    get_cups_for_teams(
      teamId,
      function (response) {
        let filterred = response.data.cups.filter(
          (item) => item.archived != true
        );
        setCupListForTeam(filterred);
      },
      function (error) {
        notifyError("Something wrong");
        console.log(error);
      }
    );
  };

  const handleSetSearchParams = () => {
    setSearchParams((params) => {
      params.set("selectedCupId", selectedCupsForTeam);
      params.set("selectedLeagueForTeam", selectedLeagueForTeam);
      return params;
    });
  };

  const handleOpenGroupPlayers = () => {
    setGroupPlayersOpen(true);
  };

  const closeGroupPlayersWindow = () => {
    setGroupPlayersOpen(false);
    if (refreshOnClose.current) {
      loadInitialData();
    }
  };

  const handleResetSquad = () => {
    let dataObj = new FormData();
    dataObj.append("team_id", thisTeam.id);
    dataObj.append("fixture_id", selectedFixture.fixture.fixture_id);

    if (selectedFixture.cup) {
      dataObj.append("cup_id", selectedFixture.cup.id);
    }

    setIsLoading(true);
    reset_squad(
      dataObj,
      function (response) {
        loadInitialData();
      },
      function (error) {
        console.log(error);
        setIsLoading(false);
        notifyError("Something wrong");
      }
    );
  };

  const handleOpenActionWithConfirmation = () => {
    setOpenActionWithConfirmation(true);
  };

  const handleGoToTeamStats = () => {
    let paramsObj = {
      teamId: searchParams.get("teamId"),
      selectedSeasonId: selectedFixture.league.season_id,
      seasonIds: searchParams.getAll("seasonIds"),
      cupIds: searchParams.getAll("cupIds"),
    };

    if (user.open_links_in_new_tabs) {
      let url = {
        pathname: "/leagues/players_data",
        search: createSearchParams(paramsObj).toString(),
      };
      window.open(`${url.pathname}?${url.search}`, "_blank");
    } else {
      navigate({
        pathname: "/leagues/players_data",
        search: createSearchParams(paramsObj).toString(),
      });
    }
  };

  const DisplayFixtureMenues = () => {
    var displayMenu = <div></div>;

    if (!selectedFixture) {
      return;
    }

    if (
      selectedFixture.default_squad == true ||
      lineupSubmitted == false ||
      savedLineup == false ||
      editBeforeGameStart
    ) {
      displayMenu = DisplayPhase1();
    }

    if ((lineupSubmitted || lineupConfirmed) && !editBeforeGameStart) {
      displayMenu = DisplayPhase2();
    }

    if (pmCompleted && !editInPhase3) {
      displayMenu = DisplayPhase3();
    }

    if (pmCompleted && editInPhase3) {
      displayMenu = DisplayEditAfterPmComplete();
    }

    return displayMenu;
  };

  const DisplayPhase1 = () => {
    return (
      <>
        <FutureMatchHeader
          fixtureData={selectedFixture}
          readyToSave={readyToSave}
          readyToSubmit={readyToSubmit}
          formation={formation}
          setFormation={setFormation}
          saveNewSquad={saveNewSquad}
          submitNewSquad={submitNewSquad}
          editBeforeGameStart={editBeforeGameStart}
          editSquad={editSquad}
          numberOfSelectedXI={numberOfSelectedXI}
          focusOnOtherTeam={focusOnOtherTeam}
          startingChange={startingChange}
          disalbedForAction={disalbedForAction}
          homeTeam={homeTeam}
          awayTeam={awayTeam}
          thisTeam={thisTeam}
          handleShowTransferModal={handleShowTransferModal}
          setShowTransferDataModal={setShowTransferDataModal}
          handleOpenGroupPlayers={handleOpenGroupPlayers}
          dotMenuOptionsList={dotMenuOptionsList}
          handleResetSquad={handleResetSquad}
          squadDataUnsavedChanges={squadDataUnsavedChanges}
        />

        <DragDropContext onDragEnd={reorderList}>
          <Droppable droppableId="dropI">
            {(droppableProvided, snapshot) => (
              <PlayersList
                ref={droppableProvided.innerRef}
                {...droppableProvided.droppableProps}
              >
                {squadData?.map((item, index) => {
                  return (
                    <Draggable
                      draggableId={item.player_id.toString()}
                      index={index}
                      key={item.player_id}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <Player
                            editBeforeGameStart={editBeforeGameStart}
                            key={item.player_id}
                            selectedFixture={selectedFixture}
                            data={item}
                            index={index}
                            handleFieldChange={handleFieldChange}
                            displayPlayerAverageStats={
                              displayPlayerAverageStats
                            }
                            handleHightlight={handleHightlight}
                            checkHighlightedElement={checkHighlightedElement}
                            user={user}
                          />
                        </div>
                      )}
                    </Draggable>
                  );
                })}
              </PlayersList>
            )}
          </Droppable>
        </DragDropContext>
      </>
    );
  };

  const DisplayPhase2 = () => {
    return (
      <>
        <FinishedMatchHeader
          fixtureData={selectedFixture}
          readyToSave={readyToSave}
          updateSquad={updateSquad}
          allPlayersConfirmed={allPlayersConfirmed}
          setEditBeforeGameStart={setEditBeforeGameStart}
          handleCheckAll={handleCheckAll}
          handleMatchPreviewButton={handleMatchPreviewButton}
          handleOpenConfAttendance={handleOpenConfAttendance}
          handleSetConfirmedAttendance={handleSetConfirmedAttendance}
          focusOnOtherTeam={focusOnOtherTeam}
          editInPhase3={editInPhase3}
          isLoading={isLoading}
          disalbedForAction={disalbedForAction}
          homeTeam={homeTeam}
          awayTeam={awayTeam}
          thisTeam={thisTeam}
          handleShowTransferModal={handleShowTransferModal}
          setIsLoading={setIsLoading}
          dotMenuOptionsList={dotMenuOptionsList}
          handleResetSquad={handleResetSquad}
          handleOpenGroupPlayers={handleOpenGroupPlayers}
          squadDataUnsavedChanges={squadDataUnsavedChanges}
        />
        <DragDropContext onDragEnd={reorderList}>
          <Droppable droppableId="dropI">
            {(provided, snapshot) => (
              <PlayersList ref={provided.innerRef} {...provided.droppableProps}>
                {squadData?.map((item, index) => {
                  return (
                    <Draggable
                      draggableId={item.player_id.toString()}
                      index={index}
                      key={item.player_id}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <PlayerFinishedMatch
                            key={item.player_id}
                            data={item}
                            index={index}
                            handleFieldChange={handleFieldChange}
                            displayPlayerAverageStats={
                              displayPlayerAverageStats
                            }
                            selectedFixture={selectedFixture}
                            useXI={false}
                            checkHighlightedElement={checkHighlightedElement}
                            user={user}
                          />
                        </div>
                      )}
                    </Draggable>
                  );
                })}
              </PlayersList>
            )}
          </Droppable>
        </DragDropContext>
      </>
    );
  };

  const DisplayPhase3 = () => {
    return (
      <>
        <FinishedMatchHeader2
          fixtureData={selectedFixture}
          setEditInPhase3={setEditInPhase3}
          focusOnOtherTeam={focusOnOtherTeam}
          handleSetConfirmedAttendance={handleSetConfirmedAttendance}
          homeTeam={homeTeam}
          awayTeam={awayTeam}
          thisTeam={thisTeam}
          handleShowTransferModal={handleShowTransferModal}
          dotMenuOptionsList={dotMenuOptionsList}
          handleResetSquad={handleResetSquad}
          handleOpenGroupPlayers={handleOpenGroupPlayers}
        />
        <PlayersList>
          {squadData?.map((item, index) => (
            <PlayerFinishedMatch2
              key={item.player_id}
              data={item}
              index={index}
              handleFieldChange={handleFieldChange}
              displayPlayerAverageStats={displayPlayerAverageStats}
              selectedFixture={selectedFixture}
              checkHighlightedElement={checkHighlightedElement}
              user={user}
            />
          ))}
        </PlayersList>
      </>
    );
  };

  const DisplayEditAfterPmComplete = () => {
    return (
      <>
        <PmCompleteHeaderEdit
          fixtureData={selectedFixture}
          readyToSave={readyToSave}
          updateSquad={updateSquad}
          allPlayersConfirmed={allPlayersConfirmed}
          setEditBeforeGameStart={setEditBeforeGameStart}
          handleCheckAll={handleCheckAll}
          handleMatchPreviewButton={handleMatchPreviewButton}
          handleOpenConfAttendance={handleOpenConfAttendance}
          handleSetConfirmedAttendance={handleSetConfirmedAttendance}
          focusOnOtherTeam={focusOnOtherTeam}
          editInPhase3={editInPhase3}
          isLoading={isLoading}
          disalbedForAction={disalbedForAction}
          homeTeam={homeTeam}
          awayTeam={awayTeam}
          thisTeam={thisTeam}
          handleShowTransferModal={handleShowTransferModal}
          setIsLoading={setIsLoading}
          dotMenuOptionsList={dotMenuOptionsList}
          handleResetSquad={handleResetSquad}
          handleOpenGroupPlayers={handleOpenGroupPlayers}
          squadDataUnsavedChanges={squadDataUnsavedChanges}
        />
        <DragDropContext onDragEnd={reorderList}>
          <Droppable droppableId="dropI">
            {(provided, snapshot) => (
              <PlayersList ref={provided.innerRef} {...provided.droppableProps}>
                {squadData.map((item, index) => {
                  return (
                    <Draggable
                      draggableId={item.player_id.toString()}
                      index={index}
                      key={item.player_id}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <PlayerPmCompleteEdit
                            key={item.player_id}
                            data={item}
                            index={index}
                            handleFieldChange={handleFieldChange}
                            displayPlayerAverageStats={
                              displayPlayerAverageStats
                            }
                            useXI={false}
                            checkHighlightedElement={checkHighlightedElement}
                            displayPlayerNotes={displayPlayerNotes}
                            fixtureData={selectedFixture}
                            user={user}
                          />
                        </div>
                      )}
                    </Draggable>
                  );
                })}
              </PlayersList>
            )}
          </Droppable>
        </DragDropContext>
      </>
    );
  };

  const dotMenuOptionsList = [
    {
      optionTitle: "Transfer Data",
      optionIcon: <TransferIcon />,
      optionAction: handleShowTransferModal,
      requestAdmin: false,
    },
    {
      optionTitle: "Reset Squad",
      optionIcon: <ResetIcon />,
      optionAction: handleOpenActionWithConfirmation,
      optionDisabled: "phase3",
      requestAdmin: true,
    },
  ];

  useEffect(() => {
    loadInitialData();
  }, [selectedCupsForTeam, selectedLeagueForTeam, searchParams.get("teamId")]);

  useEffect(() => {
    verifySaveStep1();
    verifySaveStep2();
    createOrderList();
    calculateStartingChances();
  }, [squadData, formation, numberOfSelectedXI]);

  useEffect(() => {
    handleGetCupListForTeam(searchParams.get("teamId"));
    handleGetLeagueListForTeam(searchParams.get("teamId"));
  }, []);

  useEffect(() => {
    onScroll();
  }, [fixtureList, windowWidth, listRef.current?.clientWidth]);

  useEffect(() => {
    checkSubmittedLineup();
    populateTeams();
    checkThisTeam();
  }, [selectedFixture, fixtureList]);

  useEffect(() => {
    handleSetSearchParams();
  }, [selectedCupsForTeam, selectedLeagueForTeam]);

  useEffect(() => {
    window.addEventListener("resize", setWindowDimensions);
    return () => {
      window.removeEventListener("resize", setWindowDimensions);
    };
  }, []);

  return (
    <Container>
      <ActionWithConfirmation
        variant={"reset-squad"}
        open={openActionWithConfirmation}
        setOpen={setOpenActionWithConfirmation}
        handleResetSquad={handleResetSquad}
      />

      {showEditPlayersModal && (
        <EditPlayersView
          teamID={thisTeam}
          closeWindow={closeEditPlayersWindow}
          refreshOnClose={refreshOnClose}
          leagueData={selectedFixture.league}
          cupData={selectedFixture.cup}
        />
      )}

      {showTransferDataModal && (
        <TransferDataModal
          setShowModal={setShowTransferDataModal}
          showModal={showTransferDataModal}
          leagueData={selectedFixture.league}
          fixtureData={selectedFixture}
          homeTeam={homeTeam}
          awayTeam={awayTeam}
          thisTeam={thisTeam}
          formation={formation}
          matchTransferOptionsList={matchTransferOptionsList}
          setMatchTransferOptionsList={setMatchTransferOptionsList}
          getFixturesAvailableForDataTransfer={
            getFixturesAvailableForDataTransfer
          }
          handleTransferDataButton={handleTransferDataButton}
          transferDataToThisMatch={transferDataToThisMatch}
          setTransferDataToThisMatch={setTransferDataToThisMatch}
          handleTransferMoldaClose={handleTransferMoldaClose}
          isLoading={isLoading}
        />
      )}

      {groupPlayersOpen && (
        <GroupPlayersModal
          closeWindow={closeGroupPlayersWindow}
          squadData={selectedFixture.squad}
          setIsLoading={setIsLoading}
          refreshOnClose={refreshOnClose}
          loadInitialData={loadInitialData}
        />
      )}
      {/* {resetSquadOpen &&
        <ResetSquadModal
        />
      } */}

      <FullScreenSpinner loading={isLoading} />

      <Header>
        <BackToLeaguesButton onClick={() => handleBackToLeagues()}>
          <BackToLeaguesIcon />
          back to leagues
        </BackToLeaguesButton>
        <HeaderGroup>
          <LeaguesForTeamMenu
            value={selectedLeagueForTeam}
            setValue={setSelectedLeagueForTeam}
            valuesList={leagueListForTeam}
          />
          <CupsForTeamMenu
            value={selectedCupsForTeam}
            setValue={setSelectedCupsForTeam}
            valuesList={cupListForTeam}
          />
          <FilterFixturesMenu
            value={fixtureFilter}
            setValue={setFixtureFilter}
          />
          {fixtureList.length > 0 && (
            <NavigateToEditTeamPlayers onClick={() => handleGoToTeamStats()}>
              <GoToStatsIcon />
              Stats
            </NavigateToEditTeamPlayers>
          )}
          {fixtureList.length > 0 && (
            <NavigateToEditTeamPlayers
              onClick={() => displayEditPlayersWindow()}
            >
              <EditPencilIcon />
              Edit players
            </NavigateToEditTeamPlayers>
          )}
        </HeaderGroup>
      </Header>

      <Content>
        <ListContent>
          <ScrollBar>
            <BackButton
              disabled={scrollPosition.left}
              onClick={() => decrement()}
            >
              <BackArrows />
            </BackButton>

            <NextButton
              disabled={scrollPosition.right}
              onClick={() => increment()}
            >
              <NextArrows />
            </NextButton>
          </ScrollBar>

          <MatchList id="matchList" onScroll={() => onScroll()} ref={listRef}>
            {fixtureList
              ?.slice(0)
              .reverse()
              .filter(fixtureListFilter)
              .map((item, index) => (
                <MatchElement
                  key={index}
                  index={index}
                  data={item}
                  selectedFixture={selectedFixture}
                  handleSelectFixture={handleSelectFixture}
                  displayPlayerNotes={displayPlayerNotes}
                  displayTeamNotes={displayTeamNotes}
                  orderList={orderList}
                  handleHightlight={handleHightlight}
                  highlightedElementIndex={highlightedElement}
                  unhighlightAll={unhighlightAll}
                  checkHighlightedElement={checkHighlightedElement}
                  handleSetConfirmedAttendance={handleSetConfirmedAttendance}
                  displayPlayerGameStats={displayPlayerGameStats}
                />
              ))}
          </MatchList>
        </ListContent>

        <FutureMatch>{DisplayFixtureMenues()}</FutureMatch>
      </Content>

      <Menu
        id={MENU_ID}
        style={{
          backgroundColor: "none",
          background: "none",
          boxShadow: "unset",
          padding: "unset",
          borderRadius: "10px",
          minHeight: `688px`,
        }}
      >
        <PlayerMetricsSeasonCard
          playerStatsPopupLoading={playerStatsPopupLoading}
          playerAverageStats={playerAverageStats.average_player_stats}
          playerLeague={playerAverageStats.league}
          playerTeam={playerAverageStats.team}
          seasonAverage={playerAverageStats.seasonAverage}
          fixture={playerAverageStats.fixture}
        />
      </Menu>

      {showPlayerNotes && (
        <NotePopupPanel
          closeNote={handleShowPlayerNotes}
          fixture={fixtureForConversation}
          player={playerForConversation}
          loadInitialData={loadInitialData}
          thisTeam={thisTeam}
          handleMessageicon={handleMessageicon}
        />
      )}

      {showFixtureNotes && (
        <SquadFixtureNotePopupPanel
          closeNote={handleShowTeamNotes}
          fixture={fixtureForConversation}
          searchParams={searchParams}
        />
      )}
    </Container>
  );
};

export default SquadManager;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 1600px;
  min-height: calc(100vh - 45px);
`;
const Header = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.squadManager.headerBg};
  padding: 8px 14px;
  justify-content: space-between;
`;

const Content = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  min-height: calc(100vh - 85px);
  overflow: none;
`;
const ListContent = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.squadManager.bg};
  padding-top: 2px;
  overflow: hidden;
  width: 100%;
`;
const MatchList = styled.div`
  display: flex;
  flex-direction: row-reverse;
  height: 100%;
  column-gap: 3px;
  padding-top: 1px;
  padding-right: 3px;
  padding-left: 5px;
  background: ${({ theme }) => theme.squadManager.bg};
  overflow: hidden;
  width: 100%;
`;
const FutureMatch = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 3px;
  min-width: fit-content;
  background: ${({ theme }) => theme.squadManager.bg};
  padding-top: 2px;
`;

const PlayersList = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 3px;
`;

const ScrollBar = styled.div`
  display: flex;
  min-height: 30px;
  background: ${({ theme }) => theme.squadManager.bg};
  justify-content: space-between;
  padding: 5px 15px;
`;
const BackButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  :disabled {
    opacity: 0;
  }
`;
const NextButton = styled(BackButton)``;
const BackToLeaguesButton = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  background: none;
  border: none;
  column-gap: 10px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 150%;
  color: ${({ theme }) => theme.squadManager.textColor};
`;
const NavigateToEditTeamPlayers = styled.button`
  display: inline-flex;
  align-items: center;
  column-gap: 5px;
  color: var(--primaryBlue);
  border-radius: 3px;
  border: 1px solid var(--primaryBlue);
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  background: white;
  padding: 0 8px;
  cursor: pointer;
  :disabled {
    cursor: inherit;
    opacity: 0.5;
  }
`;
const SpinnerWrapper = styled.div`
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const AbsDiv = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Bx = styled.div`
  height: 100px;
  width: 100px;
  background: gray;
`;
const HeaderGroup = styled.div`
  display: flex;
  column-gap: 15px;
`;

const OptionItem = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  background: ${({ theme }) => theme.editLeague.bg};
  p {
    font-family: Roboto;
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
    color: ${({ theme }) => theme.editLeague.groupsTextColor};
  }
`;
